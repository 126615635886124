.eventPost {
  padding: 1.6rem;
  position: relative;

  .backButton {
    color: #000;
    font-weight: 500;
    &:hover {
      color: #777;
    }
  }

  figure {
    margin: 3.2rem 0;
    padding: 0;
    &, img {
      max-width: 100%;
      height: auto;
    }
  }

  p {
    max-width: 580px;
    a {
      color: #000;
      font-weight: 500;
    }
  }

  a {
    &,
    &:visited,
    &:active {
      color: #242424;
    }
  }

  @media (min-width: 1048px) {
    padding: 4.0rem 3.2rem;

    h2 {
      max-width: 580px;
    }

    .eventPost__timeWrapper {
      position: absolute;
      right: 20%;
      top: 7.2rem;
    }

  }
}