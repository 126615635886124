.App {
  text-align: left;
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  min-height: 100vh;
  width: 100%;
}

.main {
  grid-area: main;
}
