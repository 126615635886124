.pageWrapper {
  padding: 1.6rem;
  h3 {
    max-width: 580px;
  }

  @media (min-width: 768px) {
    padding: 1.6rem 3.2rem;
  }
}
.tabParent {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0 1.6rem;
  li {
    text-transform: capitalize;
    text-align: center;
    a {
      font-weight: 500;
      display: block;
      padding: 2.0rem 0;
      margin-right: 1.6rem;
      border-bottom: 2px solid transparent;
      height: 100%;
      box-sizing: border-box;

      &:hover, &.active {
        border-bottom: 2px solid #6c0000;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0 3.2rem;
    li {
      text-align: left;
      a {
        margin-right: 3.2rem;
      }
    }
  }
}