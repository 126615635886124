@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&family=Roboto:wght@300;400;500;700&display=swap');

html {
  font-size: 62.5%;
  min-height: 100%;
  width: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  width: 100%;
  background-color: #e5e5e5;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #242424;
  &:hover {
    color: #444;
  }
}

p {
  font-weight: 300;
  line-height: 2.8rem;
}

h1 {
  font-family: 'Public Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: 4.0rem;
  line-height: 4.4rem;
}

h2 {
  font-weight: 700;
  font-size: 3.0rem;
}

h3 {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 8.0rem;
    line-height: 8.4rem;
  }
  h2 {
    font-size: 4.0rem;
  }
  h3 {
    font-size: 2.8rem;
    line-height: 4.0rem;
  }
}