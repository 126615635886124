@mixin button-style {
  font-size: 1.6rem;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 34px;
    margin-left: 0.8rem;
    border-radius: 15px;
    background-image: url(/images/arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}