.header {
  background-color: #242424;
  color: #fff;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: stretch;
  padding: 0 3.2rem;
  grid-area: header;
  .headerLink {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .headerTitle {
    font-size: 2.4rem;
    font-weight: 500;
    margin: 0;
    margin-left: 1.6rem;
    letter-spacing: 0.1rem;
  }

  @media (min-width: 768px) {
    .headerLink {
      padding: 0;
    }
  }
}

.menuButtonWrapper {
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;

  @media (min-width: 768px) {
    display: none;
  }
}

.menuButton {
  position: relative;
  border: 0;
  &,
  &:after,
  &:before {
    width: 24px;
    height: 2px;
    margin: 0;
    background-color: #fff;
    background-image: none;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
  }
  &:after {
    bottom: 8px;
  }
  &:before {
    top: 8px;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 75%;
  height: 100%;
  background-color: #242424;
  right: -100%;
  top: 0;
  padding-top: 3.2rem;
  transition: right 0.2s ease-in;
  &:before {
    content: '';
    position: fixed;
    background: rgba(#242424, 0.7);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
  a {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    padding: 0.8rem 2.4rem;
    position: relative;
    letter-spacing: 0.1rem;
    
    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      background-color: transparent;
      top: 0;
      left: 0;
      transition: all 0.2s ease-in;
      transform: scaleX(0);
    }

    &:hover, &.active {
      &:before {
        transform: scaleX(1);
        background-color: #fff;
      }
    }
  }
  .closeNav {
    font-size: 1rem;
    letter-spacing: 0.3rem;
    text-align: right;
    padding: 2.4rem;
    z-index: 1;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      width: 2px;
      height: 16px;
      position: absolute;
      right: 84px;
      top: 22px;
    }
    &:before {
      transform: rotate(-45deg);
      top: 23px;
    }
  }

  &.isShown {
    right: 0;
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    width: auto;
    height: auto;
    flex-direction: row;
    padding-top: 0;
    right: auto;
    &:before {
      display: none;
    }
    a {
      padding: 3.6rem 1.6rem 3rem;

      &:before {
        width: 100%;
        height: 4px;
      }
    }
    .closeNav {
      display: none;
    }
  }
}